<template>
    <div class="np-progress-container">
      <div
        class="np-progress-loader"
        v-bind:style="'height:' + loadedPercentage + '%'"
      ></div>
      <div class="np-progress-overlay">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
      </div>
    </div>
</template>

<script>
import { Tweenable } from 'shifty'

export default {
    data() {
        return {
        loadedPercentage: 0
        };
    },
    props: [ 'duration' ],
    emits: [ 'onloadEnd' ],
    methods: {
        tweenBar() {
            var self = this;
            const tweenable = new Tweenable()
            tweenable.setConfig({
                from: { height: 0 },
                to: { height: 100 },
                duration: this.duration,
                easing: 'linear',
                render: state => self.loadedPercentage = state.height,
            })

            var start = () => {
                tweenable.tween().then(
                    () => {
                        // Call onload end
                        self.$emit('onloadEnd')
                        // Loop progress bar
                        start()
                    }
                )
            }

            start()
        }
    },
    mounted() {
        if (window.location.pathname === '/') {
            this.tweenBar()
        }
    }
};
</script>
<!-- MenuRestoCategory.vue -->
<script>
import MenuRestoItem from './MenuRestoItem.vue'
export default {
    data() {
        return {
            rootUrl: process.env.VUE_APP_SITE_URL
        }
    },
    components: {
        MenuRestoItem
    },
    props: [
        'items',
        'category'
    ],
    computed: {
        getCategory() {
            return (this.category && this.category.length && this.category[0]) ? this.category[0] : ''
        }
    }
}
</script>

<template>
    <div :class="['menu-resto-category']" v-if="items.length > 0">
        <header v-if="getCategory">
            <div class="image" v-if="getCategory.categoryImage && getCategory.categoryImage.length"><img :src="rootUrl + getCategory.categoryImage[0].url" width="130" height="115"></div>
            <div class="category-title" v-if="getCategory.title"><h2>{{ getCategory.title }}</h2></div>
        </header>
        <div class="menu-items">
            <MenuRestoItem 
                v-for="item in items"
                :key="item.id"
                :item="item"
                :category="getCategory" />
        </div>
    </div>
</template>

<!-- MenuBarCategory.vue -->
<script>
import MenuPrice from "./MenuPrice.vue";
import MenuPriceTable from "./MenuPriceTable.vue";
export default {
    data() {
        return {
            rootUrl: process.env.VUE_APP_SITE_URL
        };
    },
    props: [
        "title",
        "drink",
        "category"
    ],
    components: { 
        MenuPrice,
        MenuPriceTable 
    },
    computed: {
        drinkReference() {
            return this.drink && this.drink.drinkReference && this.drink.drinkReference[0] ? this.drink.drinkReference[0] : "";
        },
        description() {
            const description = (this.drinkReference && this.drinkReference.drinkType && this.drinkReference.drinkType.length && this.drinkReference.drinkType[0].title) ? this.drinkReference.drinkType[0].title : "";
            return this.drink.menuItemDescription || description;
        },
        categoryType() {
            return (this.category && this.category.slug) ? this.category.slug : "";
        },
        labelUrl() {
            let label = "";
            if (this.drink && this.drink.label && this.drink.label.length) {
                label = this.drink.label[0].url;
            }
            else if (this.drinkReference && this.drinkReference.label && this.drinkReference.label.length) {
                label = this.drinkReference.label[0].url;
            }
            return (label) ? this.rootUrl + label : "";
        },
        alcoholByVol() {            
            if (this.drinkReference && this.drinkReference.alcoholByVolume !== undefined && this.drinkReference.alcoholByVolume !== null) {
                return this.drinkReference.alcoholByVolume;
            } else if (this.drink && this.drink.alcoholByVolume !== undefined && this.drink.alcoholByVolume !== null) {
                return this.drink.alcoholByVolume;
            } else {
                return null;
            }
        },
        typeHandle() {
            const kebabCase = string => string
                .replace(/([a-z])([A-Z])/g, "$1-$2")
                .replace(/[\s_]+/g, "-")
                .toLowerCase();
            return (this.drink && this.drink.typeHandle) ? kebabCase(this.drink.typeHandle) : "";
        },
    },
    methods: {
        themeColour() {
            let theme = "";
            const colour = (this.drinkReference && this.drinkReference.themeColour) ? this.drinkReference.themeColour : "";
            switch (colour) {
                case "greenblue":
                    theme = "#89ccc0";
                    break;
                case "green":
                    theme = "#49af86";
                    break;
                case "yellow":
                    theme = "#edd66b";
                    break;
                case "orange":
                    theme = "#d99383";
                    break;
                case "purple":
                    theme = "#b38bb5";
                    break;
                default:
                    theme = colour;
                    break;
            }
            return theme;
        }
    }
}
</script>

<template>
    <div class="menu-item menu-item-bar" 
        :class="[ categoryType, typeHandle ]" v-if="drink">
        <div class="menu-item-inner" v-if="drink.typeHandle === 'tastingSet'">
            <div class="col col-name">
                <h3>{{ drink.title }}</h3>
            </div>
            <div class="col" v-if="drink.soldOut">
                <div class="sold-out">uitverkocht</div>
            </div>
            <div class="col col-price" v-else>
                <div class="item-info" v-if="description" v-html="description"></div>
                <MenuPrice :price="drink.price"></MenuPrice>
            </div>
        </div>
        <div class="menu-item-inner" v-else-if="drink.typeHandle === 'food'">
            <div class="col col-name">
                <div class="item-name item-name-label" :style="{ backgroundColor: themeColour(drinkReference.themeColour) }" v-if="labelUrl"><img :src="labelUrl" /></div>
                <h3 class="item-name" :style="{ backgroundColor: themeColour(drinkReference.themeColour) }" v-else>{{ drink.title }}</h3>
                <div class="item-info">
                    <span class="item-description" v-if="description" v-html="description"></span>
                </div>
            </div>
            <div class="col col-price" v-if="drink.soldOut">
                <div class="sold-out">uitverkocht</div>
            </div>
            <div class="col col-price" v-else>
                <MenuPrice :price="drink.price"></MenuPrice>
            </div>
        </div>
        <div class="menu-item-inner" v-else>
            <div class="col col-name">
                <div class="item-name item-name-label" :style="{ backgroundColor: themeColour(drinkReference.themeColour) }" v-if="labelUrl"><img :src="labelUrl" /></div>
                <h3 class="item-name" :style="{ backgroundColor: themeColour(drinkReference.themeColour) }" v-else>{{ drink.title }}</h3>
                <div class="item-info">
                    <span v-if="alcoholByVol !== null"><strong>{{ vueNumberFormat(alcoholByVol, {precision: 1, suffix: '%'}) }}</strong></span> 
                    <span class="item-description" v-if="description" v-html="description"></span>
                </div>
            </div>
            <div class="col col-price" v-if="drink.soldOut">
                <div class="sold-out">uitverkocht</div>
            </div>
            <div class="col col-price" v-else>
                <MenuPriceTable :prices="drink.drinkPrices" />
            </div>
        </div>
    </div>
</template>

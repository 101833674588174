<!-- MenuRestoItem.vue -->
<script>
import MenuPriceTable from "./MenuPriceTable.vue";
export default {
    props: [
        'item',
        'category'
    ],
    components: { MenuPriceTable },
    computed: {
        allergens() {
            return (this.item && this.item.allergens && this.item.allergens.length) ? this.item.allergens : '';
        },
        categoryType() {
            return (this.category && this.category.slug) ? this.category.slug : ''
        },
        typeHandle() {
            const kebabCase = string => string
                .replace(/([a-z])([A-Z])/g, "$1-$2")
                .replace(/[\s_]+/g, '-')
                .toLowerCase();
            return (this.item && this.item.typeHandle) ? kebabCase(this.item.typeHandle) : ''
        },
        getPriceHeader() {
            return (this.item && this.item.priceHeader && this.item.priceHeader.length) ? this.item.priceHeader[0] : ''
        }
    },
}
</script>

<template>
    <div class="menu-item menu-item-resto" 
        :class="[ categoryType, typeHandle ]" v-if="item">
        <div class="menu-item-inner">
            <div class="col col-name">
                <h3 class="item-name">
                    <div class="item-title">
                        {{ item.title }}
                        <span v-if="item.vegan"> <em>vegan</em></span>
                        <span v-if="item.vegi"> <em>veggie</em></span>
                    </div>
                    <ul class="allergens" v-if="allergens">
                        <li v-for="allergen in allergens" :key="allergen.id" :class="allergen.slug">
                            <span>{{ allergen.title }}</span>
                        </li>
                    </ul>
                </h3>
                <div class="item-info" v-if="item.menuItemDescription"><span class="item-description" v-html="item.menuItemDescription" /></div>
            </div>
            <div class="col col-price" v-if="item.soldOut">
                <div class="sold-out">uitverkocht</div>
            </div>
            <div class="col col-price" v-else>
                <MenuPriceTable :header="getPriceHeader" :prices="item.restoPrices" />
            </div>
        </div>
    </div>
</template>

<template>
    <div class="menu">
      <div v-if="$apollo.loading">Loading..</div>
      <div v-else>
        <div class="grid menu-resto-grid">
          <div class="grid-message">
            <img src="@/assets/img/middagkaart.png" width="420" height="332" alt="" />
            <h1 class="grid-message-title" v-text="global.kitchenBreakTitle"></h1>
            <div class="grid-message-content" v-html="global.kitchenBreakMessage"></div>
          </div>
          <div class="grid-item">
            <MenuBarCategory :isBatteliek="true" v-for="category in categoriesBar" :items="menuBarFilter(category.id)" :category="categoryBarFilter(category.id)" :key="category.id" />
            <MenuRestoCategory v-for="category in categoriesResto" :items="menuRestoFilter(category.id)" :category="categoryRestoFilter(category.id)" :key="category.id" />
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import gql from 'graphql-tag'
import MenuBarCategory from './menu/MenuBarCategory.vue'
import MenuRestoCategory from './menu/MenuRestoCategory.vue'

const menuRestoQuery = gql`
  query getMenuResto {
    itemsResto: entries(section: "menuResto") {
      id
      title
      ... on menuResto_default_Entry {
        id
        allergens {
          id
          slug
          title
        }
        menuItemDescription
        menuRestoCategory {
          slug
          title
          id
        }
        priceHeader {
          col1
          col2
          col3
        }
        restoPrices {
          ... on restoPrices_BlockType {
            id
            col1
            col2
            col3
          }
        }
        vegi
        vegan
        soldOut
      }
    }
  }
  `
const categoriesRestoQuery = gql`
  query getCategoriesResto {
    categoriesResto: categories(group: "menuResto", availableKitchenBreak: true) {
      id
      title
      ... on menuResto_Category {
        id
        title
        slug
        categoryImage {
          url
        }
        column
        lft
        availableKitchenBreak
      }
    }
  }
`

const menuBarQuery = gql`
  query getMenuBar {
    itemsBar: entries(section: "menu") {
      ...DrinkEntry
      ...BatteliekDrinkEntry
      ...TastingSetEntry
      ...FoodEntry
    }
  }

  fragment DrinkEntry on menu_drink_Entry {
    title
    lft
    menuItemDescription
    typeHandle
    ... on menu_drink_Entry {
      id
      alcoholByVolume
      soldOut
      menuCategory {
        id
        title
        slug
      }
      drinkPrices {
        ... on drinkPrices_BlockType {
          id
          col1
          col2
          col3
        } 
      }
    }
  }

  fragment BatteliekDrinkEntry on menu_batteliekDrink_Entry {
    title
    lft
    menuItemDescription
    typeHandle
    ... on menu_batteliekDrink_Entry {
      id
      alcoholByVolume
      label {
        url
      }
      soldOut
      menuCategory {
        id
        title
        slug
      }
      drinkPrices {
        ... on drinkPrices_BlockType {
          id
          col1
          col2
          col3
        } 
      }
      drinkReference {
        ... on drinks_beer_Entry {
          id
          alcoholByVolume
          drinkType {
            id
            title
          }
          label {
            url
          }
          description
        }
        ... on drinks_lemonade_Entry {
          id
          label {
            url
          }
          description
        }
        ... on drinks_spirit_Entry {
          id
          alcoholByVolume
          label {
            url
          }
          description
        }
      }
    }
  }

  fragment TastingSetEntry on menu_tastingSet_Entry {
    title
    lft
    menuItemDescription
    typeHandle
    ... on menu_tastingSet_Entry {
      id
      soldOut
      menuCategory {
        id
        title
        slug
      }
      price
    }
  }

  fragment FoodEntry on menu_food_Entry {
    title
    lft
    menuItemDescription
    typeHandle
    ... on menu_food_Entry {
      id
      soldOut
      menuCategory {
        id
        title
        slug
      }
      price
    }
  }
  `
const categoriesBarQuery = gql`
  query getCategoriesBar {
    categoriesBar: categories(group: "menuBar", availableKitchenBreak: true) {
      id
      title
      ... on menuBar_Category {
        id
        barRow
        barColumn
        priceHeader {
          col1
          col2
          col3
        }
        title
        slug
        availableKitchenBreak
      }
    }
  }
`

export default {
    data () {
        return {
          categoriesBar: [],
          categoriesResto: [],
          itemsResto: [],
          itemsBar: [],
          
        }
    },
    components: {
      MenuRestoCategory,
      MenuBarCategory
    },
    props: [ 'global' ],
    apollo: {
      categoriesBar: {
        query: categoriesBarQuery,
        pollInterval: 2000
      },
      categoriesResto: {
        query: categoriesRestoQuery,
        pollInterval: 2000
      },
      itemsBar: {
        query: menuBarQuery,
        pollInterval: 2000
      },
      itemsResto: {
        query: menuRestoQuery,
        pollInterval: 2000
      }
    },
    methods: {
      menuRestoFilter(category) {
        return this.itemsResto.filter(item => item.menuRestoCategory && item.menuRestoCategory[0].id == category)
      },
      menuBarFilter(category) {
        return this.itemsBar.filter(item => item.menuCategory && item.menuCategory[0].id == category)
      },
      categoryRestoFilter(category) {
        return this.categoriesResto.filter(item => item && item.id == category)
      },
      categoryBarFilter(category) {
        return this.categoriesBar.filter(item => item && item.id == category)
      },
      categoryColumn(col) {
        const sortedCategories = [...this.categoriesResto]
        sortedCategories.sort((a, b) => a['lft'] - b['left'])
        return sortedCategories.filter(item => item && item.column == col)
      }
    }
}
</script>
<!-- MenuPrice.vue -->
<script>
export default {
    props: [
        'price',
    ]
}
</script>

<template>
    <div class="price">{{ vueNumberFormat(price, {prefix: '€'}) }}</div>
</template>
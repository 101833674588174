<!-- MenuPriceTable.vue -->
<script>
    export default {
        props: [
            'header',
            'prices',
        ]
    }
    </script>
    
    <template>
        <table v-if="prices" class="price-table">
            <tr v-if="header">
                <th class="price price-1"><span v-if="header.col1">{{ header.col1 }}</span></th>
                <th class="price price-2"><span v-if="header.col2">{{ header.col2 }}</span></th>
                <th class="price price-3"><span v-if="header.col3">{{ header.col3 }}</span></th>
            </tr>
            <tr v-for="price in prices" :key="price.id">
                <td class="price price-1"><span v-if="price.col1">{{ vueNumberFormat(price.col1, {prefix: '€'}) }}</span></td>
                <td class="price price-2"><span v-if="price.col2">{{ vueNumberFormat(price.col2, {prefix: '€'}) }}</span></td>
                <td class="price price-3"><span v-if="price.col3">{{ vueNumberFormat(price.col3, {prefix: '€'}) }}</span></td>
            </tr>
        </table>
    </template>
import { createApp, h } from 'vue'
import App from './App.vue'
import VueNumberFormat from 'vue-number-format'
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client/core'
import { createApolloProvider } from '@vue/apollo-option'
import { setContext } from '@apollo/client/link/context';
// import  { createRouter, createWebHistory } from 'vue-router';
// import MenuBarData from './components/MenuBarData.vue'
// import MenuRestoData from './components/MenuRestoData.vue'

import './assets/css/style.scss';

const uri = process.env.VUE_APP_API_URL;
// const authorization = 'Bearer kjGy_4NV1_XH4bfdmKF8GGyHlAHZTvN2';
const authorization = process.env.VUE_APP_API_AUTHORIZATION_HEADER;

const httpLink = new HttpLink({
    uri: uri,
})  

const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: authorization,
      }
    }
});

// Create the apollo client
const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    connectToDevTools: true,
})

const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
})

const app = createApp({
    render: () => h(App),
})

app.use(VueNumberFormat, {prefix: '', decimal: ',', thousand: '.'}) 
app.use(apolloProvider)

app.mount('#app')